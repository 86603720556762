import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import KTLayoutFlyout from "@/assets/js/layout/extended/quick-flyout.js";
import { VALIDATORS } from "@/common/validators";
import ApiService from "@/core/services/api.service";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import { formatUTCToLocal, formatLocalToUTC } from "@/common/date-format";
import VoucherApiService from "@/core/services/api.service.vouchers";
import CountryService from "@/core/services/country.service";

export default {
  props: {
    //program: Object,
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "add-limitation",
  data() {
    return {
      valid: false,
      rules: {
        required: VALIDATORS.required
      },
      countries: [],
      cities: [],
      shopList: [],
      shopListLoading: false,
      citiesLoading: false,
      countriesLoading: false,
      addShopsDisable: false,
      searchShops: "",
      type: "new",
      shopsTableHeaders: [
        {
          text: "ID",
          value: "id",
          sortable: false,
          align: "left"
        },
        {
          text: this.$i18n.t("commonTable.shopName"),
          value: "name",
          sortable: false,
          align: "left"
        },
        {
          text: "",
          value: "delete",
          sortable: false,
          width: "3%"
        }
      ],
      selected: {
        shops: [],
        cities: [],
        country: null
      },
      form: {}
    };
  },
  created() {
    this.setForm();
  },
  async mounted() {
    await SchedulerApiService.init();
    await ApiService.init();
  },
  computed: {
    isFormValid() {
      const formValid = this.$refs.form?.validate();
      const shopsExist = this.form.shops.length;
      const datesExist = this.form.startsAt && this.form.endsAt;

      return formValid && shopsExist && datesExist;
    }
  },
  methods: {
    initFlyout() {
      KTLayoutFlyout.init(this.$refs["add_limitation"]);
      const el = KTLayoutFlyout.getElement();
      this.flyoutRef = new KTOffcanvas(el);
    },
    setForm(data) {
      const shops = data?.shops ? [...data?.shops] : [];

      this.form = {
        id: data?.id,
        name: data?.name || null,
        startsAt: this.type !== "copy" ? formatUTCToLocal(data?.startsAt) : null,
        endsAt: this.type !== "copy" ? formatUTCToLocal(data?.endsAt) : null,
        limit: data?.limit || null,
        shops: shops
      };
    },
    async open(obj) {
      this.type = obj?.type || "new";

      this.initFlyout();
      this.flyoutRef.show();

      this.resetSelectedData();

      await this.getCountries();
      if (this.type === "edit") {
        await this.getCities();
      }
      await this.getShops();
      this.setForm(obj.data);
    },
    resetSelectedData() {
      this.selected = {
        shops: [],
        cities: [],
        country: null
      };
    },
    close() {
      this.setForm();
      this.cities = [];
      this.shops = [];
      if (this.$refs.startsAt) {
        this.$refs.startsAt.clearHandler();
      }

      if (this.$refs.endsAt) {
        this.$refs.endsAt.clearHandler();
      }

      this.flyoutRef.hide();
    },
    previousState() {
      this.$router.go(-1);
    },
    async validateAndSave() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },

    handleSelectAllShops(val) {
      this.selected.shops = val ? this.shopList : [];
    },
    handleSelectAllCities(val) {
      this.selected.cities = val ? this.cities : [];

      this.getShops();
    },
    onCountryChange() {
      this.getCities();
      this.getShops();
    },
    onCityChange() {
      this.getShops();
    },
    async getShops() {
      const countryId = this.selected.country?.id;
      const cityIds = this.selected.cities.map(c => c.id).join(",");
      const params = {
        countryId: countryId || null,
        cityIds: cityIds || null
      };

      this.shopListLoading = true;
      await ApiService.query("api/shops/dropdown-list", { params })
        .then(res => {
          this.shopList = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopListLoading = false));
    },
    getCountries() {
      this.countriesLoading = true;
      return SchedulerApiService.getCountries()
        .then(res => {
          this.countries = res.data || [];

          if (!this.selected?.country) {
            const countryId = CountryService.getActiveCountry();
            this.selected.country = this.countries.find(country => country.id === countryId);
            this.getCities();
          }
        })
        .finally(() => {
          this.countriesLoading = false;
        });
    },
    getCities() {
      if (!this.selected.country?.id) return;

      this.citiesLoading = true;
      return SchedulerApiService.getCities({
        countryId: this.selected.country?.id
      })
        .then(res => {
          this.cities = res.data || [];
        })
        .finally(() => {
          this.citiesLoading = false;
        });
    },
    addShopsToList() {
      this.addShopsDisable = false;
      const list = [...this.selected.shops];

      const existing = this.form.shops.map(item => item.id);
      const filterExisting = list.filter(item => !existing.includes(item.id));

      this.form.shops.push(...filterExisting);
      this.selected.shops = [];
    },
    async save() {
      const postModel = { ...this.form };

      if (postModel?.startsAt) {
        postModel.startsAt = formatLocalToUTC(postModel.startsAt);
      }

      if (postModel?.endsAt) {
        postModel.endsAt = formatLocalToUTC(postModel.endsAt);
      }

      let promise = null;
      if (this.type === "edit") {
        promise = VoucherApiService.updateVoucherCampaign(postModel);
      } else {
        promise = VoucherApiService.createVoucherCampaign(postModel);
      }

      promise
        .then(response => {
          this.close();
          this.setForm();
          this.$emit("save", {
            formData: postModel,
            response
          });

          this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
            title: this.$i18n.t("menu.voucherLimitations"),
            solid: true,
            variant: "success",
            autoHideDelay: 1000
          });
        })
        .catch(error => {
          let formattedError = error;

          if (!error?.response?.data?.message) {
            const translation = this.$i18n.t("voucher.campaignWarning");
            formattedError = new Error(translation);
          }

          this.$bvToast.toast(`${formattedError}`, {
            title: this.$i18n.t("menu.voucherLimitations"),
            solid: true,
            variant: "error",
            autoHideDelay: 2000
          });
        });
    },
    async delShopFromList(item) {
      this.form.shops = this.form.shops.filter(fItem => fItem.id !== item.id);
    }
  }
};
