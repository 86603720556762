import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import AddLimitation from "./offcanvas/add-limitation.vue";
import VoucherApiService from "@/core/services/api.service.vouchers";
import { formatUTCToLocal } from "@/common/date-format";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import CountryService from "@/core/services/country.service";

import {
  CAMPAIGN_HEADERS,
  ARCHIVE_HEADERS,
  SHOP_HEADERS,
  INNER_SHOP_HEADERS
} from "./voucher-tables";

export default {
  name: "voucher-limitations",
  components: { AddLimitation },
  data() {
    return {
      itemExpanded: [],
      itemExpandedArchive: [],
      voucherData: [],
      voucherDataLoading: false,
      pagination: {},
      voucherArchiveData: [],
      voucherArchiveDataLoading: false,
      paginationArchive: {},
      shopData: [],
      shopDataLoading: false,
      shopDataPagination: {},
      total: 0,
      totalArchive: 0,
      shopDataTotal: 0,
      cities: [],
      countries: [],
      shopFilters: {
        search: "",
        country: null,
        cityIds: null
      },
      tabs: "campaigns",
      componentLoaded: false,
      countriesLoading: false,
      citiesLoading: false
    };
  },
  watch: {
    pagination: {
      handler() {
        if (!this.componentLoaded) return;
        this.getActiveVoucherCampaigns();
      },
      deep: true
    },
    paginationArchive: {
      handler() {
        if (!this.componentLoaded) return;
        this.getArchiveVoucherCampaigns();
      },
      deep: true
    },
    shopDataPagination: {
      handler() {
        if (!this.componentLoaded) return;
        this.getVoucherShops();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.voucherLimitations");
    },
    headers() {
      return CAMPAIGN_HEADERS({ i18n: this.$i18n });
    },
    archiveHeaders() {
      return ARCHIVE_HEADERS({ i18n: this.$i18n });
    },
    shopHeaders() {
      return SHOP_HEADERS({ i18n: this.$i18n });
    },
    innerShopHeaders() {
      return INNER_SHOP_HEADERS({ i18n: this.$i18n });
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await VoucherApiService.init();

    if (this.tabs === "campaigns") {
      this.getActiveVoucherCampaigns();
      this.getArchiveVoucherCampaigns();
    } else {
      this.getVoucherShops();
      this.getCountries();
    }

    this.componentLoaded = true;
  },
  methods: {
    toggleTab(tabName) {
      this.tabs = tabName;
      if (this.tabs === "shops") {
        this.getCountries();
      }
    },
    onOpenFlyout(event, type) {
      const update = {
        data: event ? { ...event } : undefined,
        type
      };

      this.$refs.addLimitationFlyout.open(update);
    },
    onFlyoutSave() {
      this.getActiveVoucherCampaigns();
      this.getArchiveVoucherCampaigns();
    },
    onGoToShopEdit(item) {
      this.$router.push({ path: `/voucher-limitations/shop/${item.id}` });
    },
    async getActiveVoucherCampaigns() {
      const config = {
        params: {
          isActive: true,
          pageNumber: this.pagination.page - 1,
          pageSize: this.pagination.itemsPerPage
        }
      };

      this.voucherDataLoading = true;
      await VoucherApiService.getVoucherCampaigns(config)
        .then(res => {
          this.voucherData = res.data?.campaigns?.results || [];
          this.total = res.data?.campaigns?.totalCount || 0;
        })
        .finally(() => {
          this.voucherDataLoading = false;
        });
    },
    async getArchiveVoucherCampaigns() {
      const config = {
        params: {
          isActive: false,
          pageNumber: this.paginationArchive.page - 1,
          pageSize: this.paginationArchive.itemsPerPage
        }
      };

      this.voucherArchiveDataLoading = true;
      await VoucherApiService.getVoucherCampaigns(config)
        .then(res => {
          this.voucherArchiveData = res.data?.campaigns?.results || [];
          this.totalArchive = res.data?.campaigns?.totalCount || 0;
        })
        .finally(() => {
          this.voucherArchiveDataLoading = false;
        });
    },
    async getVoucherShops() {
      const countryId = this.shopFilters.country?.id;
      const cityIds = this.shopFilters.cityIds?.map(item => item.id);

      const config = {
        params: {
          query: this.shopFilters.search || null,
          pageNumber: this.shopDataPagination.page - 1,
          pageSize: this.shopDataPagination.itemsPerPage,
          countryIds: countryId || null,
          cityIds: cityIds || null
        }
      };

      this.shopDataLoading = true;
      await VoucherApiService.getVoucherShops(config)
        .then(res => {
          this.shopData = res.data?.shops?.results || [];
          this.shopDataTotal = res.data?.shops?.totalCount || 0;
        })
        .finally(() => {
          this.shopDataLoading = false;
        });
    },
    getReachedLimit(item) {
      return item.shops?.filter(item => item.remaining === 0)?.length || 0;
    },
    getInAction(item) {
      return item.shops?.filter(item => item.remaining !== 0)?.length || 0;
    },
    formatUTCToLocal: formatUTCToLocal,
    onDeleteItem(item) {
      this.onDelete(item).then(() => {
        this.getActiveVoucherCampaigns();
      });
    },
    onDeleteArchiveItem(item) {
      this.onDelete(item).then(() => {
        this.getArchiveVoucherCampaigns();
      });
    },
    async onDelete(item) {
      return await VoucherApiService.deleteVoucherCampaign(item.id).then(() => {
        this.$bvToast.toast(this.$i18n.t("common.savedSuccessfully"), {
          title: this.$i18n.t("menu.voucherLimitations"),
          solid: true,
          variant: "success",
          autoHideDelay: 1000
        });

        return true;
      });
    },

    handleSelectAllCities(val) {
      this.shopFilters.cityIds = val ? this.cities : [];
    },
    onCountryChange() {
      this.getCities();
    },
    getCountries() {
      this.countriesLoading = true;
      return SchedulerApiService.getCountries()
        .then(res => {
          this.countries = res.data || [];
          if (!this.shopFilters?.country) {
            const countryId = CountryService.getActiveCountry();
            this.shopFilters.country = this.countries.find(country => country.id === countryId);
            this.getCities();
          }
        })
        .finally(() => {
          this.countriesLoading = false;
        });
    },
    getCities() {
      if (!this.shopFilters.country?.id) return;

      this.citiesLoading = true;
      return SchedulerApiService.getCities({
        countryId: this.shopFilters.country?.id
      })
        .then(res => {
          this.cities = res.data || [];
        })
        .finally(() => {
          this.citiesLoading = false;
        });
    },
    filterShops() {
      this.shopDataPagination.page = 1;
      this.getVoucherShops();
    },
    clearSearch() {
      this.shopFilters = {
        search: "",
        country: null,
        cityIds: null
      };
      this.shopDataPagination.page = 1;
      this.getVoucherShops();
    }
  }
};
