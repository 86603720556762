import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

/**
 * Service to call HTTP request via Axios
 */
const SchedulerApiService = {
  URL: "",

  async init() {
    Vue.use(VueAxios, axios);
    const resp = await Vue.axios.get(`api/url/get-base-uri`);
    this.URL = resp.data;
  },

  //endpoints
  API_CITIES: "api/main-feed/city",
  API_SHOP_LIST: "api/shops/dropdown-list",
  API_COUNTY: "api/main-feed/country",
  API_SCHEDULER: "api/main-feed/scheduler",
  API_IMAGE_UPLOAD: "api/upload/image-upload-without-saving-to-db",
  API_OOBLEE_CATEGORIES: "api/main-feed/shopping-experience",
  /**
   * Send the GET HTTP request
   * @param params
   * @returns {*}
   */
  async getCities(params) {
    const config = {
      params
    };

    try {
      return await Vue.axios.get(this.URL + this.API_CITIES, config);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] SchedulerApiService ${error}`);
    }
  },

  /**
   * Send the GET HTTP request
   * @returns {*}
   */
  async getShops() {
    try {
      return await Vue.axios.get(this.API_SHOP_LIST);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] SchedulerApiService ${error}`);
    }
  },

  /**
   * Send the GET HTTP request
   * @returns {*}
   */
  async getCountries() {
    try {
      return await Vue.axios.get(this.URL + this.API_COUNTY);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] SchedulerApiService ${error}`);
    }
  },

  uploadImage(image) {
    // this.init();
    //const URLr = 'https://apicloud-test.ooblee.me/';
    try {
      const formData = new FormData();
      formData.append("image", image);
      return Vue.axios.post(this.URL + this.API_IMAGE_UPLOAD, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] MessagesApiService ${error}`);
    }
  },

  /**
   * Send the GET HTTP request
   * @param params
   * @returns {*}
   */
  async getSchedulerList(params = {}) {
    //GET {{baseUrlFeed}}/api/main-feed/scheduler?cityId={{cityId}}&startDate=1691076361&endDate=1691076361&shopId={{shopId}}

    const config = {
      params
    };

    try {
      return await Vue.axios.get(this.URL + this.API_SCHEDULER, config);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] SchedulerApiService ${error}`);
    }
  },

  /**
   * Send the POST HTTP request
   * @param body
   * @returns {*}
   */
  async saveSchedule(body) {
    try {
      return await Vue.axios.post(this.URL + this.API_SCHEDULER, body);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] SchedulerApiService ${error}`);
    }
  },

  async query(params) {
    let config = {
      params
    };
    try {
      return Vue.axios.get(this.URL + this.API_OOBLEE_CATEGORIES, config);
    } catch (error) {
      this.$log.error("Error: ", error);
      throw new Error(`[KT] ApiService ${error}`);
    }
  }
};
export default SchedulerApiService;
