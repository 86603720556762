import moment from "moment";

export const COMMON_DATE_FORMATS = {
  LONG: "yyyy-MM-DD HH:mm:ss",
  LONG_NO_SECONDS: "yyyy-MM-DD HH:mm",
  FULL_MONTH: "DD MMMM yyyy"
};

export const formatUTCDate = (date, format = COMMON_DATE_FORMATS.LONG) => {
  if (date == null || date === "") return null;

  return moment
    .utc(date)
    .local()
    .format(format);
};

export const formatUTCToLocal = (date, format = COMMON_DATE_FORMATS.LONG) => {
  if (date == null || date === "") return null;

  return moment
    .utc(date)
    .local()
    .format(format);
};

export const formatLocalToUTC = date => {
  if (date == null || date === "") return null;

  return moment(date)
    .utc()
    .format();
};
