export const SHOP_HEADERS = ({ i18n }) => [
  {
    text: i18n.t("common.nameOfShop"),
    value: "shop",
    sortable: false
  },
  {
    text: i18n.t("common.activeLimitsSum"),
    value: "activeSum",
    sortable: false,
    align: "center",
    width: "15%"
  },
  {
    text: i18n.t("common.leftLimitsSum"),
    value: "leftSum",
    sortable: false,
    align: "center",
    width: "15%"
  },
  {
    text: i18n.t("common.spentLastMonth"),
    value: "spent",
    sortable: false,
    align: "center",
    width: "15%"
  },
  {
    text: i18n.t("common.spentLastYear"),
    value: "spentYear",
    sortable: false,
    align: "center",
    width: "15%"
  }
];

export const ARCHIVE_HEADERS = ({ i18n }) => [
  {
    text: i18n.t("common.nameOfShop"),
    value: "shop",
    sortable: false
  },
  {
    text: i18n.t("common.all"),
    value: "all",
    sortable: false
  },
  {
    text: i18n.t("common.spent"),
    value: "spent",
    sortable: false
  },
  {
    text: i18n.t("common.left"),
    value: "left",
    sortable: false
  }
];

export const CAMPAIGN_HEADERS = ({ i18n }) => [
  {
    text: i18n.t("common.nameOfLimit"),
    value: "name",
    sortable: false,
    align: "left"
  },
  {
    text: i18n.t("common.validFrom"),
    value: "validFrom",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("common.validUntil"),
    value: "validUntil",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("common.limitPerShop"),
    value: "limitPerShop",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("common.shopsChosen"),
    value: "shopsChosen",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("common.inAction"),
    value: "inAction",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: i18n.t("common.reachedTheLimit"),
    value: "limit",
    sortable: false,
    width: "10%",
    align: "center"
  },
  {
    text: " ",
    value: "actions",
    width: "3%",
    sortable: false
  }
];

export const SHOP_LIMIT_HEADERS = ({ i18n }) => [
  {
    text: i18n.t("common.nameOfLimit"),
    value: "name",
    sortable: false,
    width: "30%",
    align: "left"
  },
  {
    text: i18n.t("common.validFrom"),
    value: "validFrom",
    sortable: false,
    align: "center"
  },
  {
    text: i18n.t("common.validUntil"),
    value: "validUntil",
    sortable: false,
    align: "center"
  },
  {
    text: i18n.t("common.limitSet"),
    value: "limitSet",
    sortable: false,
    align: "center"
  },
  {
    text: i18n.t("common.spent"),
    value: "spent",
    sortable: false,
    align: "center"
  },
  {
    text: i18n.t("common.left"),
    value: "left",
    sortable: false,
    align: "center"
  }
];

export const INNER_SHOP_HEADERS = ({ i18n }) => [
  {
    text: i18n.t("common.nameOfShop"),
    value: "shopName",
    sortable: false
  },

  {
    text: i18n.t("common.spent"),
    value: "spent",
    sortable: false,
    align: "center"
  },
  {
    text: i18n.t("common.left"),
    value: "left",
    sortable: false,
    align: "center"
  }
];
