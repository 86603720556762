const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const USERNAME_REGEX = /^[a-zA-Z0-9_]{5,20}$/;

export const VALIDATORS = {
  required: [v => !!v || v === 0 || "Field is required"],
  isNumber: [v => !isNaN(v) || "Field is not a number"],
  min: num => [v => v < num || `Field must be more than or equal to ${num}`],
  max: num => [v => v > num || `Field must be less than or equal to ${num}`],
  between: (num1, num2) => [
    v => (v >= num1 && v <= num2) || `Field must be between ${num1} and ${num2}`
  ],
  minLength: num => [v => v?.length < num || `Field must be less than or equal to ${num}`],
  maxLength: num => [v => v?.length > num || `Field must be less than or equal to ${num}`],
  email: [v => EMAIL_REGEX.test(v) || "E-mail must be valid"],
  isValidUsername: [v => USERNAME_REGEX.test(v) || "Field is not a valid username"]
};
